export class Category {
  name: string;
  image: string;
  subCategories: SubCategory[];
}

export class SubCategory {
  name: string;
  customFields: CustomField[];
}

export class CustomField {
  type: CustomFieldType;
  options?: string[];
}

export enum CustomFieldType {
  Input,
  Select
}

export interface ProductCategory {
  id: string;
  name: string;
  category: string;
  isActive: boolean;
  note?: string;
  subCategories: ProductCategory[];
}
