import { FirebaseMetaData } from './firebase.meta';

export enum BannerPosition {
  MainBanner,
  SideBanner
}

export interface PromoBannerSchedule {
  startDate: any;
  endDate: any;
}

export interface PromoBanner {
  name: string;
  position: BannerPosition;
  schedule: PromoBannerSchedule;
}

export interface PromoBannerRecord extends FirebaseMetaData {
  source: string;
  promoBanner: PromoBanner;
}
