export interface Province {
  id: number;
  description: string;
}

export interface City {
  id: number;
  description: string;
  prov_dcode: number;
}

export class PermanentAddress {
  streetAddress: string;
  barangay: string;
  region: string;
  city: string;
  postalCode: string;
}
