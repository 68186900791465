import { BaseMetaData } from './firebase.meta';

export enum LendiCreditType {
  CashIn,
  Spending
}

export interface LendiCreditRecord extends BaseMetaData {
  id: string;
  amount: number;
  price: number;
  type: LendiCreditType;
}


export interface LendiCredits extends BaseMetaData {
  amount: number;
  price: number;
}
