import { FirebaseMetaData } from './firebase.meta';
import { ProductPickUpLocation, LendiProductRecord } from './product.model';
import { AppDeliveryFee } from './app-settings.model';
import { LendiUserRecord } from './user-profile.model';
import { LendiRiderRecord } from './rider.model';

export enum VerificationStatus {
  Pending,
  Approved,
  Denied
}

export enum BorrowRequestStatus {
  Pending,
  Current,
  Completed,
  Cancelled
}

export enum SecurityDepositStatus {
  Pending,
  Refunded,
  OnHold,
  Forfeited
}

export enum LenderPayoutStatus {
  Pending,
  Processing,
  Completed
}

export enum PaymentStatus {
  Pending,
  Successful,
  Failed
}

export interface Payment extends FirebaseMetaData {
  status: PaymentStatus;
  cardDetails: CardDetails;
}

export interface CardDetails {
  ccName: string;
  ccNumber: string;
  ccExpiry: ExpiryDate;
  ccCode: number;
}

export interface ExpiryDate {
  expiryMonth: number;
  expiryYear: number;
}

export interface BorrowRequest {
  fromDate: string;
  fromTime: string;
  toDate: string;
  toTime: string;
  deliveryAddress: ProductPickUpLocation;
}

export interface BorrowRequestFees {
  adminFee: number;
  deliveryFee: AppDeliveryFee;
  distance: number;
  rentalFee: number;
  totalRentalFee: number; //days x rental fee
  totalPayment: number; // deliveryFee + totalDiscountedRentalFee + adminFee
  totalDeliveryFee: number;
  totalDiscountedRentalFee: number; //totalRentalFee - totalDiscount
  totalDiscount: number;
}

export interface PayoutDetails {
  isPaid: boolean;
  amount: number;
  remarks: string;
  updatedAt: any;
}

export interface BorrowRequestRecord extends FirebaseMetaData {
  uid: string;
  product: LendiProductRecord;
  borrowRequest: BorrowRequest;
  payment: Payment;
  fees: BorrowRequestFees;
  status: BorrowRequestStatus;
  securityDepositStatus: SecurityDepositStatus;
  lenderPayoutStatus: LenderPayoutStatus;
  itemTrackerStatus: ItemTrackerStatus;
  itemTracker?: ItemTracker;
  lenderToBorrowerRider?: any;
  borrowerToLenderRider?: any;
  verificationStatus: VerificationStatus;
  isPaid?: boolean;
  payoutDetails?: PayoutDetails;
}

export interface BorrowRequestState {
  borrowRequest: BorrowRequest;
  product: LendiProductRecord;
  productOwner: LendiUserRecord;
  deliveryFee: AppDeliveryFee;
  distance: number;
}

export interface ItemTracker extends FirebaseMetaData {
  itemTrackerStatus: ItemTrackerStatus;
  riderProfile?: LendiRiderRecord;
}

export enum ItemTrackerStatus {
  Pending,
  Processing,
  AssignedRiderFromLendertoBorrower,
  RiderOnTheWayToLender,
  FailedPickUpFromLender,
  PickedUpFromLender,
  FailedDeliveryToBorrower,
  DeliverySuccessfulToBorrower,
  AssignedRiderFromBorrowerToLender,
  RiderOnTheWayToBorrower,
  FailedPickUpFromBorrower,
  PickedUpFromBorrower,
  FailedDeliveryToLender,
  DeliverySuccessfulToLender,
  TransactionCancelled,
}
