export enum MessageType {
  Text,
  Media
}

export interface ChatMessage {
  type: MessageType;
  message: string;
  createdAt: any;
  uid: string;
  id: string;
}
